import axios from "axios";

import moment from "moment-timezone";
moment.tz.setDefault("Asia/Seoul");
axios.defaults.withCredentials = true;
const devlog = true;
window.console.log("seller개발 log 상태:" + devlog);
const console = {
  log: (log) => {
    if (devlog) window.console.log(log);
  },
};

export const fixSellerAPI = (formData) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_HOST + "/api/v1/seller/fixseller", formData, {
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded",
        // },
      })
      .then((response) => {
        console.log("<<axios response- fixseller>>");
        resolve(response.data);
      })
      .catch(function (error) {
        alert(error);
        reject(error);
        throw error;
      });
  });
};

export const getSellerPaylogAPI = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_HOST + "/api/v1/seller/getsellerpaylog")
      .then((response) => {
        console.log("<<axios response- getSellerPaylogAPI>>");
        resolve(response.data);
      })
      .catch(function (error) {
        alert(error);
        reject(error);
        throw error;
      });
  });
};
