import Agency from "../agency/Agency";
import SellerInfo from "../info/SellerInfo";
import Paylogs from "../paylogs/Paylogs";
import TopBar from "../topbar/TopBar";

const Main = (props) => {
  return (
    <main className="no-drag" style={{ width: "100%", height: "100%" }}>
      <TopBar {...props} />
      <div style={{ height: "80px" }} />

      <SellerInfo {...props} />

      <Paylogs {...props} />

      <Agency {...props} />
    </main>
  );
};

export default Main;
