import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import { fixPasswordAPI } from "../../api/auth";

const PWModal = (props) => {
  const { onClose, set_loading, logout } = props;

  const current_pw_ref = useRef();
  const new_pw_ref = useRef();
  const new_pw2_ref = useRef();

  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);

      const current_pw = formData.get("current_pw");
      const new_pw = formData.get("new_pw");
      const new_pw2 = formData.get("new_pw2");

      console.log("current_pw", current_pw);
      console.log("new_pw", new_pw);
      console.log("new_pw2", new_pw2);

      if (!current_pw) {
        setInvalidPasswordMsg("현재 비밀번호를 입력해주세요.");
        current_pw_ref?.current?.focus();
        return;
      }
      if (!new_pw) {
        setInvalidPasswordMsg("새로운 비밀번호를 입력해주세요.");
        new_pw_ref?.current?.focus();
        return;
      }
      if (!new_pw2) {
        setInvalidPasswordMsg("새로운 비밀번호를 입력해주세요.");
        new_pw2_ref?.current?.focus();
        return;
      }

      if (new_pw !== new_pw2) {
        setInvalidPasswordMsg("새로운 비밀번호가 일치하지 않습니다.");
        new_pw_ref?.current?.focus();
        return;
      }

      try {
        set_loading(true);
        const res = await fixPasswordAPI({
          current_pw,
          new_pw,
          new_pw2,
        });

        console.log("res", res);

        if (!res.valid) {
          toast.error(res.msg);
          if (res.redirect) {
            logout();
          }
          return;
        }

        toast.success(
          <div>
            비밀번호 변경 완료!
            <br />
            다음 로그인부터 새로운 비밀번호로 로그인하시면 됩니다.
          </div>
        );

        onClose();
      } catch (err) {
        console.log("err", err);
        toast.error(err?.message);
      } finally {
        set_loading(false);
      }
    },
    [onClose, set_loading, logout]
  );

  return (
    <div className="__wrapper">
      <div style={{ width: 600, maxWidth: "90vw", maxHeight: "90vh" }}>
        <div className="__top">
          <div className="__title">비밀번호 변경</div>
          <div className="__close">
            <button style={{ fontSize: 24 }} onClick={onClose}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="__content">
          <form onSubmit={handleSubmit}>
            <div className="grid" style={{ padding: 0, gridTemplateColumns: "1fr 2fr" }}>
              <div className="grid__header">현재 비밀번호</div>
              <div>
                <input type="password" ref={current_pw_ref} className="l_input" placeholder="현재 비밀번호를 입력해주세요." name="current_pw" />
              </div>
              <div className="grid__header">변경 비밀번호</div>
              <div>
                <input type="password" ref={new_pw_ref} className="l_input" placeholder="변경할 비밀번호를 입력해주세요." name="new_pw" />
              </div>
              <div className="grid__header">변경 비밀번호 확인</div>
              <div>
                <input type="password" ref={new_pw2_ref} className="l_input" placeholder="변경할 비밀번호를 한 번 더 입력해주세요." name="new_pw2" />
              </div>
              {invalidPasswordMsg && (
                <>
                  <div
                    className="grid__item"
                    style={{
                      height: "auto",
                      minHeight: "unset",
                      justifyContent: "flex-start",
                      gridColumn: "1/3",
                      gap: 10,
                      paddingTop: 10,
                      paddingLeft: 10,
                      border: 0,
                      color: "#dc3545",
                      fontSize: "0.9em",
                    }}
                  >
                    * {invalidPasswordMsg}
                  </div>
                </>
              )}
              <div
                className="grid__item"
                style={{
                  gridColumn: "1/3",
                  justifyContent: "flex-end",
                  gap: 10,
                  paddingTop: 10,
                  border: 0,
                }}
              >
                <button className="btn">변경하기</button>
                <button className="btn btn-red" onClick={onClose}>
                  취소하기
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PWModal;
