import { useCallback, useMemo, useState } from "react";
import { BigCard } from "../../components";
import "./SellerInfo.scss";
import RadioInput from "../../components/radioinput/RadioInput";
import ModalPortal from "../../components/ModalPortal";
import PWModal from "./PWModal";
import { fixSellerAPI } from "../../api/seller";
import { toast } from "react-toastify";

const mapper = {
  개인일용노무비: 0,
  프리랜서용역매출: 1,
  사업자매출: 2,
};

const SellerInfo = (props) => {
  const { userinform, set_userinform, set_sessionchecking, set_loading, logout } = props;

  const [selectRadio, setSelectRadio] = useState(mapper[userinform?.seller_payment_method] || 0);
  const [showPWModal, setShowPWModal] = useState(false);

  const bankList = useMemo(() => {
    return [
      "농협",
      "국민",
      "카카오뱅크",
      "신한",
      "기업",
      "우리",
      "대구",
      "하나",
      "새마을",
      "부산",
      "경남",
      "우체국",
      "광주",
      "SC",
      "신협",
      "케이뱅크",
      "씨티",
      "수협",
      "전북",
      "제주",
      "산업",
      "SBI저축",
      "NH투자",
      "토스뱅크",
    ];
  }, []);

  console.log("userinform", userinform);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);

      const reversedMapper = {};
      for (const key in mapper) {
        const value = mapper[key];
        reversedMapper[value] = key;
      }

      const phone = formData.get("phone");
      const email = formData.get("email");
      const address = formData.get("address");
      const method = reversedMapper[selectRadio];
      const owner = formData.get("owner");
      const registration_number = formData.get("registration_number");
      const bank = formData.get("bank");
      const account_number = formData.get("account_number");
      const account_holder = formData.get("account_holder");

      try {
        const res = await fixSellerAPI({
          phone,
          email,
          address,
          method,
          owner,
          registration_number,
          bank,
          account_number,
          account_holder,
        });

        if (!res.valid) {
          toast.error(res.msg);
          if (res.redirect) {
            logout();
          }
          return;
        }
        toast.success("정보 수정 완료");
        set_userinform(null);
        set_sessionchecking(0);
      } catch (err) {
        console.log("err", err);
        toast.error(err?.message);
      } finally {
        set_loading(false);
      }
    },
    [logout, set_loading, set_userinform, set_sessionchecking, selectRadio]
  );

  return (
    <>
      <BigCard
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <strong>판매자(Seller) 정보</strong>
            </div>
          );
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="grid__header">ID</div>
            <div className="grid__item">{userinform.seller_ID}</div>
            <div
              className="grid__item"
              style={{
                gridColumn: "3/5",
                justifyContent: "flex-start",
              }}
            >
              <button className="btn" type="button" onClick={() => setShowPWModal(true)}>
                PW 변경
              </button>
            </div>
            <div className="grid__header">가입코드</div>
            <div className="grid__item">{userinform.signupcode_key}</div>
            <div className="grid__header">코드명</div>
            <div className="grid__item">{userinform.signupcode_name}</div>
            <div className="grid__header">전화</div>
            <div className="grid__item">
              <input className="l_input" placeholder="연락 가능한 번호를 입력해주세요." name="phone" defaultValue={userinform.seller_phone} />
            </div>
            <div className="grid__header">이메일</div>
            <div className="grid__item">
              <input className="l_input" placeholder="연락 가능한 이메일을 입력해주세요." name="email" defaultValue={userinform.seller_email} />
            </div>
            <div className="grid__header">주소</div>
            <div
              className="grid__item"
              style={{
                gridColumn: "2/5",
              }}
            >
              <input className="l_input" placeholder="주소지를 입력해주세요." name="address" defaultValue={userinform.seller_address} />
            </div>
            <div className="grid__header">
              사업자매출
              <br />
              (세금계산서)
            </div>
            <div
              className="grid__item radiowrapper"
              style={{
                gridColumn: "2/5",
              }}
            >
              <RadioInput
                checked={selectRadio === 0}
                name="method"
                label="개인일용노무비"
                onClick={() => {
                  setSelectRadio(0);
                }}
              />
              <RadioInput
                checked={selectRadio === 1}
                name="method"
                label="프리랜서용역매출"
                onClick={() => {
                  setSelectRadio(1);
                }}
              />
              <RadioInput
                checked={selectRadio === 2}
                name="method"
                label="사업자매출"
                onClick={() => {
                  setSelectRadio(2);
                }}
              />
              <div
                className="link"
                onClick={() => {
                  const a = document.createElement("a");
                  a.href = "https://readerseye.s3.ap-northeast-2.amazonaws.com/public/readerseye_business_registration.pdf";
                  a.target = "_blank";
                  a.rel = "noreferrer";
                  a.download = true;

                  a.click();
                }}
              >
                사업자등록증
                <br />
                받기
              </div>
            </div>
          </div>
          <div className="grid">
            <div
              className="grid__header"
              style={{
                gridColumn: "1/5",
                justifyContent: "flex-start",
              }}
            >
              수령자 정보
            </div>
            <div className="grid__header">대표자 이름</div>
            <div className="grid__item">
              <input className="l_input" placeholder="대표자 이름을 입력해주세요." name="owner" defaultValue={userinform.seller_owner} />
            </div>
            <div className="grid__header">
              주민등록번호
              <br />
              (사업자 등록번호)
            </div>
            <div className="grid__item">
              <input
                className="l_input"
                placeholder="주민등록번호(사업자 등록번호)를 입력해주세요."
                name="registration_number"
                defaultValue={userinform.seller_registration_number}
              />
            </div>
          </div>
          <div
            className="grid"
            style={{
              gridTemplateColumns: "1fr 2fr 1fr 3fr 1fr 2fr",
            }}
          >
            <div
              className="grid__header"
              style={{
                gridColumn: "1/7",
                justifyContent: "start",
              }}
            >
              수령 계좌
            </div>
            <div className="grid__header">은행</div>
            <div className="grid__item">
              <select className="select" name="bank" defaultValue={userinform.seller_bank}>
                <option value="">선택</option>
                {bankList.map((v) => (
                  <option value={v} key={v}>
                    {v}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid__header">계좌번호</div>
            <div className="grid__item">
              <input
                className="l_input"
                placeholder="계좌번호를 입력해주세요."
                name="account_number"
                defaultValue={userinform.seller_account_number}
              />
            </div>
            <div className="grid__header">예금주</div>
            <div className="grid__item">
              <input className="l_input" placeholder="예금주를 입력해주세요." name="account_holder" defaultValue={userinform.seller_account_holder} />
            </div>
            <div
              className="grid__item"
              style={{
                gridColumn: "1/7",
                justifyContent: "flex-end",
                gap: 10,
                paddingTop: 10,
                border: 0,
              }}
            >
              <button className="btn" type="submit">
                저장하기
              </button>
              <button className="btn" type="reset">
                되돌리기
              </button>
            </div>
          </div>
        </form>
      </BigCard>
      {showPWModal && (
        <ModalPortal>
          <PWModal {...props} onClose={() => setShowPWModal(false)} />
        </ModalPortal>
      )}
    </>
  );
};

export default SellerInfo;
