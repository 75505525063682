import React from 'react';
import './Loading.css';
import './Loading.scss';
const Loading = ({option,...props})=>{

    let newclass ='text';
    if(option&&option.stop){
        newclass +=' stop';
    }

    return <div className="Loading">
            
            <div className={newclass}>
                ReadersEye
            </div>


    </div>
}

export default Loading;