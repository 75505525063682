import axios from "axios";

import moment from "moment-timezone";
moment.tz.setDefault("Asia/Seoul");
axios.defaults.withCredentials = true;
const devlog = true;
window.console.log("auth개발 log 상태:" + devlog);
const console = {
  log: (log) => {
    if (devlog) window.console.log(log);
  },
};

export const sessionCheckAPI = () => {
  return new Promise(function (resolve, reject) {
    //console.log(process.env);
    let addr = process.env.REACT_APP_HOST + "/api/v1/auth/sessioncheck";
    //console.log(addr + '로 요청');

    axios
      .post(addr, { sessioncheck: true })
      .then((response) => {
        console.log("<<axios response- sessioncheck>>");
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        //console.log(error);
        resolve({
          valid: false,
          msg: error,
        });
        alert(error);
      });
  });
};
export const loginAPI = (obj) => {
  return new Promise(function (resolve, reject) {
    //console.log(process.env);
    let addr = process.env.REACT_APP_HOST + "/api/v1/auth/login";
    //console.log(addr + '로 요청');

    axios
      .post(addr, obj)
      .then((response) => {
        console.log("<<axios response- login>>");
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        //console.log(error);
        resolve({
          valid: false,
          msg: error,
        });
        alert(error);
      });
  });
};

//로그아웃
export const logoutAPI = () => {
  return new Promise(function (resolve, reject) {
    //console.log(process.env);
    let addr = process.env.REACT_APP_HOST + "/api/v1/auth/logout";
    console.log(addr + "로 요청");
    axios
      .post(addr, {
        logout: true,
      })
      .then((response) => {
        console.log("<<axios response- logout>>");
        // console.log(response);
        // dispatch({ type: "LOGOUT"});

        resolve(response.data);
      })
      .catch(function (error) {
        console.log("<<axios response- logout>>");
        console.log(error);
        resolve({
          valid: false,
          msg: error,
        });
        alert(error);
      });
  });
};

export const getAgencyListAPI = (obj) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_HOST + "/api/v1/auth/list", obj)
      .then((response) => {
        console.log("<<axios response- get_agency_list>>");
        // console.log(response);
        //REACT_APP_MODE
        if (response.data.valid) {
          let agencylist = response.data.list;

          let arr = ["agency_join_date", "pay_end_date", "pay_start_date"];
          for (let j = 0; j < agencylist.length; j++) {
            for (let key in agencylist[j]) {
              //console.log(key+"에대한검사");
              for (let i = 0; i < arr.length; i++) {
                if (key === arr[i] && agencylist[j][key] !== null) {
                  // agencylist[j][key] = agencylist[j][key].replace('T', ' ');
                  // agencylist[j][key] = agencylist[j][key].substr(0, agencylist[j][key].length - 5);

                  if (process.env.REACT_APP_MODE === "dev") {
                    agencylist[j][key] = moment(agencylist[j][key]).format("YYYY-MM-DD HH:mm:ss");
                  } else {
                    agencylist[j][key] = moment(agencylist[j][key]).utc().format("YYYY-MM-DD HH:mm:ss");
                  }
                }
              }
            }

            if (agencylist[j].iscoupon) {
              agencylist[j].iscoupon = "사용중";
            } else {
              agencylist[j].iscoupon = "아님";
            }
          }
        }
        resolve(response.data);
      })
      .catch(function (error) {
        alert(error);
        reject(error);
        throw error;
      });
  });
};

export const fixPasswordAPI = (obj) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_HOST + "/api/v1/auth/fixpassword", obj)
      .then((response) => {
        console.log("<<axios response- fixpassword>>");
        resolve(response.data);
      })
      .catch(function (error) {
        alert(error);
        reject(error);
        throw error;
      });
  });
};
