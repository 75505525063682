import React from "react";
import './InputPW.css';

const InputPW = ({ className, style, value, placeholder, onChange,onBlur, ...props }) => {

    let overridestyle = style;

    let or_className = className ? className + ' InputPW' : 'InputPW';

    //none
    const [showPW,set_showPW] = React.useState(false);


    return (<div className={or_className} style={overridestyle ? overridestyle : { width: '100%' }}>
        <input className="i" type="text" autoComplete="off"

            style={{
                WebkitTextSecurity: showPW===true?"none":"disc" }} placeholder={placeholder}
            value={value} onChange={onChange} onBlur={onBlur}/>

        <span className="eye" style={{
            fontSize:'24px',position:'absolute',right:'0px',cursor:'pointer',
            color:'rgb(115,103,240)',outline:'none',userSelect:'none',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'transparents',width:'40px'}}
         onClick={()=>set_showPW(!showPW)}>
        {
            showPW===true?
            <i className="fa fa-eye" aria-hidden="true"></i>
            :
            <i className="fa fa-eye-slash" aria-hidden="true"></i>
        }

        </span>
    </div>)


}

export default InputPW