import React from "react";

import "./App.css";
import { Route, Switch, Router, withRouter, Redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import { Ring } from "react-spinners-css";
import { sessionCheckAPI, logoutAPI } from "./api/auth";
// import Test from './pages/test/Test';
import Loading from "./pages/loading/Loading";
import Main from "./pages/main/Main";
function App({ ...props }) {
  const { history } = props;
  const [userinform, set_userinform] = React.useState(null);
  props.userinform = userinform;
  const [loading, set_loading] = React.useState(false);
  props.set_loading = set_loading;
  const [sessionchecking, set_sessionchecking] = React.useState(0);
  props.set_sessionchecking = set_sessionchecking;
  props.logout = () => {
    logoutAPI().then(() => {
      set_userinform(null);
      history.push("/login");
    });
  };
  //세션체크
  React.useEffect(() => {
    if (!userinform && sessionchecking === 0) {
      set_sessionchecking(1);

      sessionCheckAPI().then(function (res) {
        console.log(res);
        if (res.valid) {
          let data = res.results;
          // setTimeout(function(){
          set_userinform(data);
          set_sessionchecking(2);
          // },300);
        } else {
          // setTimeout(function(){
          set_userinform(null);
          set_sessionchecking(2);
          // },300);
        }
      });
    }
  }, [userinform, sessionchecking]);

  if (sessionchecking === 1) {
    //1차 로딩후  index의 2차 lazyloading 이 들어감
    return (
      <div className="App" style={{ backgroundColor: "rgb(230,230,240)" }}>
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/login"
              render={(prop) => {
                if (userinform) {
                  return <Redirect to="/" replace />;
                } else {
                  // return <Login history={history}  set_userinform={set_userinform}  {...props} />;
                  props.set_userinform = set_userinform;
                  return <Login {...props} />;
                }
              }}
            />

            <Route
              path="/"
              render={(prop) => {
                //로그인 안된상태에선 로그인페이지로 보내버림
                if (userinform) {
                  // return <Agency to="/login" />;
                  props.set_userinform = set_userinform;
                  return <Main {...props} />;
                } else {
                  return <Redirect to="/login" replace />;
                }
              }}
            />
          </Switch>
        </Router>
        {loading === true && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.7)",
              top: 0,
              left: 0,
              zIndex: "50000",
            }}
          >
            <Ring color="#7367f0" />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(App);
