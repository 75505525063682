import { useEffect, useRef, useState } from "react";
import "./TopBar.css";

const TopBar = (props) => {
  const { logout, userinform } = props;

  const [userPopup, set_userPopup] = useState(false);
  const usericonRef = useRef();

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (!usericonRef) {
        return;
      }
      if (!usericonRef.current) {
        return;
      }

      const someRefContainTarget = usericonRef.current.contains(event.target);

      if (someRefContainTarget) {
        return;
      }

      if (!userPopup) {
        return;
      } else if (userPopup && !someRefContainTarget) {
        set_userPopup(false);
      }
    };

    if (userPopup) {
      window.addEventListener("click", handleWindowClick);
    }

    return () => {
      if (userPopup) {
        window.removeEventListener("click", handleWindowClick);
      }
    };
  }, [userPopup]);

  return (
    <div className="TopBar no-drag">
      <div
        style={{
          width: "calc(100% - 30px)",
          height: "calc(100% - 15px)",
          backgroundColor: "white",
          borderRadius: "12px",
          position: "relative",
        }}
      >
        <div
          className="rightUserInformPicture"
          ref={usericonRef}
          tabIndex="0"
          onClick={() => {
            set_userPopup(true);
          }}
        >
          <div style={{ width: "200px", cursor: "pointer" }}>
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                fontSize: "14px",
                fontWeight: "700",
                color: "rgb(60,60,60)",
              }}
            >
              {userinform && userinform.seller_name ? userinform.seller_name : ""}
            </div>
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                fontSize: "13px",
                color: "rgb(60,60,60)",
              }}
            >
              {userinform && userinform.seller_ID ? userinform.seller_ID : ""}
            </div>
          </div>

          <div
            className="userIconWrapper"
            style={{
              width: "70px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "5px",
              position: "relative",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>

            <div className="userPopup_triangle" style={{ display: userPopup === true ? "block" : "none" }}></div>
            <div className="userPopup" style={{ display: userPopup === true ? "block" : "none" }}>
              <div
                style={{
                  height: "100px",
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="70"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div style={{ color: "black", marginTop: "10px" }}>{userinform.seller_name}</div>
              <div>{userinform && userinform.seller_ID ? userinform.seller_ID : ""}</div>
              {userinform.user_type === "agency" && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70px" }}>
                  <button
                    className="btn-setting"
                    onClick={() => {
                      console.log("닫아");
                      setTimeout(function () {
                        set_userPopup(false);
                      }, 10);

                      props.history.push("/setting");
                    }}
                  >
                    ReadersEye 계정 관리
                  </button>
                </div>
              )}
              <div style={{ borderBottom: "1px solid #ccc", height: "10px" }}></div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70px" }}>
                <button className="btn-logout" onClick={logout}>
                  로그아웃
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopBar;
