import "./RadioInput.scss";

const RadioInput = ({ ...props }) => {
  const { name, checked, onClick, label } = props;
  return (
    <label
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <input type="radio" name={name} checked={checked} onChange={() => {}} />
      <span className="radio"></span>
      <span>{label}</span>
    </label>
  );
};

export default RadioInput;
