import React from "react";
import "./Agency.scss";
import { BigCard, GPtable } from "../../components";
import { SelectColumnFilter, NumberRangeColumnFilter } from "../../util/Filter";
import moment from "moment";
import { getAgencyListAPI } from "../../api/auth";

let AgencyListTable_function;

const Agency = ({ ...props }) => {
  const { userinform, Swal, logout } = props;

  const [data, set_data] = React.useState([]);
  const columns = React.useMemo(() => {
    return [
      {
        Header: "가입비 납입여부",
        accessor: "ispaidregistration",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 200,
        show: false,
      },
      {
        Header: "가입코드명",
        accessor: "signupcode_name",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 200,
      },
      {
        Header: "기관아이디",
        accessor: "agency_ID",
        useFilter: true,
        width: 150,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "기관이름",
        accessor: "agency_name",
        useFilter: true,
        width: 250,
      },
      {
        Header: "지금학생수",
        accessor: "now_testee_count",
        useFilter: true,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        width: 100,
      },
      {
        Header: "최근-리딩수행수",
        accessor: "recent_tsr_count",
        useFilter: true,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        width: 100,
      },
      {
        Header: "최근-숙제생성수",
        accessor: "recent_tt_count",
        useFilter: true,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        width: 100,
      },
      {
        Header: "구독",
        accessor: "subscriptions_active",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 150,
      },
      {
        Header: "구독종료일",
        accessor: "pay_end_date",
        useFilter: true,
        width: 200,
      },
      {
        Header: "플렌이름",
        accessor: "plan_name",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 200,
      },

      {
        Header: "가입일",
        accessor: "agency_join_date",
        useFilter: true,
        width: 200,
        show: true,
      },

      {
        Header: "폰번호",
        accessor: "agency_phone",
        useFilter: true,
        width: 200,
        show: true,
      },
      {
        Header: "이메일",
        accessor: "agency_email",
        useFilter: true,
        width: 200,
        show: true,
      },
      {
        Header: "이메일인증",
        accessor: "agency_email_verify",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 100,
        show: true,
      },
      {
        Header: "주소1",
        accessor: "agency_address",
        useFilter: true,
        width: 500,
        show: true,
      },
      {
        Header: "주소2",
        accessor: "agency_address_detail",
        useFilter: true,
        width: 350,
        show: true,
      },
      {
        Header: "우편코드",
        accessor: "agency_address_postal",
        useFilter: true,
        width: 200,
        show: true,
      },
    ];
  }, []);

  const [selRow, set_selRow] = React.useState(null);
  const [isRefreshingAgencyList, set_isRefreshingAgencyList] = React.useState(false);
  const refresh_AgencyList = React.useCallback(() => {
    return new Promise(function (resolve) {
      // setloading(true);

      let pastSelectedAgencyIdx = selRow && selRow.agency_idx ? selRow.agency_idx : null;
      let pastPageIndex = AgencyListTable_function.pageIndex * 1 ? AgencyListTable_function.pageIndex * 1 : null;

      set_isRefreshingAgencyList(true);
      AgencyListTable_function.set_loading(true);
      getAgencyListAPI().then(function (res) {
        set_isRefreshingAgencyList(false);
        AgencyListTable_function.set_loading(false);

        if (res.valid) {
          let nt = moment().format("YYYY-MM-DD HH:mm:ss");

          for (let i = 0; i < res.list.length; i++) {
            if (res.list[i].subscriptions_active !== null) {
              if (res.list[i].subscriptions_active === 1) {
                if (res.list[i].pay_end_date > nt) {
                  res.list[i].subscriptions_active = "구독중";
                } else {
                  res.list[i].subscriptions_active = "과거구독했으나끊김";
                }
              } else if (res.list[i].subscriptions_active === 0) {
                res.list[i].subscriptions_active = "과거구독했으나끊김";
              }
            } else {
              res.list[i].subscriptions_active = "구독한적없음";
            }

            if (res.list[i].ispaidregistration === 2) {
              res.list[i].ispaidregistration = "지불완료";
            } else if (res.list[i].ispaidregistration === 1) {
              res.list[i].ispaidregistration = "fake지불";
            } else {
              res.list[i].ispaidregistration = "X";
            }
          }
          // console.log("pastSelectedAgencyIdx:"+pastSelectedAgencyIdx);
          for (let i = 0; i < res.list.length; i++) {
            if (pastSelectedAgencyIdx && res.list[i].agency_idx === pastSelectedAgencyIdx) {
              set_selRow(res.list[i]);
              AgencyListTable_function.set_selectedRow(res.list[i]);
              break;
            }
          }

          set_data(res.list);
          if (pastPageIndex !== null) AgencyListTable_function.gotoPage(pastPageIndex);
          resolve(1);
        } else {
          if (res.redirect) {
            logout();
          } else {
            Swal.fire(res.msg);
          }
          resolve(-1);
        }
      });
    });
  }, [Swal, logout, selRow]);

  const [justOneTimeRefreshAgencyList, set_justOneTimeRefreshAgencyList] = React.useState(false);

  React.useEffect(() => {
    if (justOneTimeRefreshAgencyList === false) {
      set_justOneTimeRefreshAgencyList(true);
      refresh_AgencyList();
    }
  }, [refresh_AgencyList, justOneTimeRefreshAgencyList]);

  return (
    <div className="Agency no-drag">
      <BigCard
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <strong>기관(Agency)리스트</strong>
            </div>
          );
        }}
      >
        {"가입코드:" + userinform.signupcode_key}
        <br />
        {"가입코드명:" + userinform.signupcode_name}

        <GPtable
          className="agencyTable"
          columns={columns}
          data={data}
          defaultPageSize={12}
          defaultToolbar={true}
          toolbar={() => {
            return (
              <>
                <div>
                  <button className="btn btn-toolbar btn-orange" disabled={isRefreshingAgencyList} onClick={() => refresh_AgencyList()}>
                    새로고침
                  </button>
                </div>
              </>
            );
          }}
          onClickRow={(e, row) => set_selRow(row)}
          GPF={(GPtable_f) => {
            // this.UserListTable_function = GPtable_f;
            AgencyListTable_function = GPtable_f;
          }}
        />
      </BigCard>
    </div>
  );
};

export default Agency;
