import React from "react";
import "./Login.css";
import "./checkbox.scss";

import { SsogiTooltip, InputPW } from "../../components";

//import backgroundImg from './background.jpg';

import { loginAPI } from "../../api/auth";

const Login = ({ alert, set_userinform, ...props }) => {
  //console.log("로그인컴퍼넌트")

  const { Swal } = props;
  // console.log(props);

  const loginRef = React.useRef();
  const [leftImgShow, set_leftImgShow] = React.useState(true);
  const [rememberID, set_rememberID] = React.useState(() => {
    //console.log("스테이트?")
    let rememberID = localStorage.getItem("rememberID");
    if (rememberID) rememberID = true;
    else rememberID = false;
    //        console.log("초기값:"+rememberID);
    return rememberID;
  });

  const [adminID, set_adminID] = React.useState(() => {
    let adminID = localStorage.getItem("adminID");
    if (!adminID) adminID = "";
    return adminID;
  });

  const [password, set_password] = React.useState("");

  /////////화면사이즈에 따른 왼쪽 그림 배치/////////////////

  React.useEffect(() => {
    const judgeImgShow = () => {
      if (!(loginRef.current && loginRef.current.clientWidth)) return;
      if (loginRef.current.clientWidth >= 788) {
        set_leftImgShow(true);
      } else {
        set_leftImgShow(false);
      }
    };
    judgeImgShow();
    window.addEventListener("resize", judgeImgShow);
    return () => {
      window.removeEventListener("resize", judgeImgShow);
    };
  }, []);

  const handleLogin = () => {
    let submitdata = {
      admin_ID: adminID,
      admin_PW: password,
    };

    props.set_loading(true);
    loginAPI(submitdata).then((res) => {
      props.set_loading(false);
      console.log(res);
      if (res.valid) {
        localStorage.setItem("adminID", adminID);
        localStorage.setItem("rememberID", 1);
        set_userinform(res.results);
      } else {
        Swal.fire({
          title: <p>오류</p>,
          html: res.msg,
          icon: "error", //warning, error, success, info, and question
          showCancelButton: false,
          confirmButtonText: "확인",
          heightAuto: false,
          // cancelButtonText: '아니요'
        });
      }
    });
  };

  return (
    <div
      className="Login no-drag"
      ref={loginRef}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
          e.preventDefault();
          if (adminID !== "" || password !== "") {
            handleLogin();
          }
          return;
        }
      }}
      style={{ background: "url('/images/background.jpg') no-repeat", backgroundSize: "cover" }}
    >
      <link rel="stylesheet" type="text/css" />
      <div className="wrap">
        <div
          style={{
            display: leftImgShow ? "flex" : "none",
            width: "300px",
            backgroundColor: "rgb(236, 249, 255)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={"/images/readerseye_login.png"} alt="" style={{ width: "90%" }} />
        </div>

        <div style={{ width: "488px" }}>
          <div className="header">
            <div style={{ display: "flex", alignItems: "center", paddingTop: "20px", position: "relative" }}>
              <img src={"/images/logo_readerseye.png"} alt="" />
              <div style={{ paddingLeft: "5px" }}>ReadersEye</div>
            </div>
            <div style={{ display: "flex", fontSize: "14px", color: "black", marginTop: "17px" }}>리더스아이 Seller 계정으로 로그인 해주세요</div>
          </div>
          <div className="container">
            <div className="inputLabel" style={{ opacity: adminID === "" ? "0" : "1", transition: ".5s" }}>
              sellerID
            </div>

            <div className="inputWrapper">
              <SsogiTooltip
                position={"top"}
                message="ID를 기입해주세요"
                option={{
                  bubbleWidth: "200px",
                  SsogiTooltipWidth: "100%",
                }}
              >
                <input
                  className="l_input"
                  type="text"
                  autoComplete="off"
                  placeholder="&#xf19c; ID"
                  maxLength={15}
                  value={adminID}
                  onChange={(e) => {
                    let val = e.target.value.toLowerCase();
                    const isLowercaseNumber = (string) => {
                      if (string) {
                        const lowercaseNumberOnly = /^[a-z0-9]+$/g;
                        return lowercaseNumberOnly.test(string);
                      } else {
                        return true;
                      }
                    };
                    if (isLowercaseNumber(val)) {
                      set_adminID(val);
                    }
                  }}
                />
              </SsogiTooltip>
            </div>

            <div className="inputLabel" style={{ opacity: password === "" ? "0" : "1", transition: ".5s" }}>
              Password
            </div>
            <div className="inputWrapper">
              <InputPW placeholder="&#xf084; USER PW" value={password} onChange={(e) => set_password(e.target.value)} />
            </div>

            <div style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ display: "flex", width: "100%", position: "relative" }}>
                <label className="checkbox bounce">
                  <input
                    type="checkbox"
                    checked={rememberID}
                    onChange={(e) => {
                      set_rememberID(!rememberID);
                      if (!rememberID === false) {
                        localStorage.removeItem("userID");
                        localStorage.removeItem("adminID");
                        localStorage.removeItem("rememberID");
                      } else {
                        localStorage.setItem("rememberID", 1);
                      }
                    }}
                  />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span style={{ paddingLeft: "5px", color: "rgb(60,60,60)", cursor: "default" }}>ID 기억하기</span>
              </div>
            </div>
          </div>
          <div className="footer">
            <button className="btn_fill" disabled={adminID === "" || password === "" ? true : false} onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
