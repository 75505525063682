
import React from "react";
import './SsogiTooltip.css';


const SsogiTooltip = ({ option, disabled, ...props }) => {
  //  console.log("갹");
  //  console.log(props);
  const bubblestyle = React.useMemo(() => {
    let bubblestyle = {
      width:'auto'
    };
    if (option) {
      bubblestyle = {};
      if (option.bubbleWidth) {
        bubblestyle.width = option.bubbleWidth;
        bubblestyle.minWidth = option.bubbleWidth;
      }
      if (option.bubbleFontWeight) {
        bubblestyle.fontWeight = option.bubbleFontWeight;
      }
    
   
    }
    return bubblestyle;
  }, [option]);

  const ststyle = React.useMemo(() => {
    let ststyle = null;
    if (option) {

      if (option.SsogiTooltipWidth) {
        ststyle = {};
        ststyle.width = option.SsogiTooltipWidth;
      }
    }
    //SsogiTooltipWidth
    return ststyle;
  }, [option]);




  const [displayTooltip, set_displayTooltip] = React.useState(false);


  let message = props.message
  let position = props.position

  //  onMouseLeave={hideTooltip}
  //onBlur={hideTooltip }

  let classname = props.className ? props.className + ' stooltip' : 'stooltip'



  //onBlur={ this.hideTooltip }
  //
  //
  let bubbleposition = '-' + position;
  if (props.position !== 'custom') {
    bubbleposition = '';
  }

  //  onMouseOver={showTooltip}


  const tref = React.useRef();
  React.useEffect(() => {
    // console.log(tref);
    let elthis = tref.current;
    const mle = () => {
      //console.log("마우스리브");

      set_displayTooltip(false); 
    }
    const focusout = function(e){
     
      /*
        console.log("포커스아웃");
        set_displayTooltip(false);
      */

      const leavingParent = !e.target.contains(e.relatedTarget);

      if (leavingParent) {
          // do things in response to fully leaving the parent element and all of its children
//        console.log(111);
        set_displayTooltip(false);
      }
    

      

    }

    if(option.type !== 'clickShow'){




      if (elthis) {

        elthis.addEventListener('mouseleave', mle);
      }
    }
    else{
      elthis.addEventListener('focusout',focusout)
    }

    return () => {
      

      if(option.type !== 'clickShow'){
        if (elthis) {
          elthis.removeEventListener('mouseleave', mle);
        }
      }
      else{
        if(elthis){
          elthis.removeEventListener('focusout',focusout);
        }
      }

    }
  }, [tref,option]);


  let bubbleClass = props.position==='custom1'?'stooltip-message-custom1':'stooltip-message';
  
  if(props.position==='bottomleft'){
    bubbleClass = 'stooltip-message-bottomleft';
  }


    return (

      <div className={classname} style={ststyle!==null?ststyle:{width:'auto'}} ref={tref} 
        onMouseEnter={()=>{
          if(option.type !== 'clickShow'){
            set_displayTooltip(true)
          }
        }}
        onClick={()=>{
          if(option.type === 'clickShow'){
            set_displayTooltip(true)
          }
        }}
     

        tabIndex="0"
      >
 
        {displayTooltip && bubblestyle !== null && disabled !== true &&
          <div className={`stooltip-bubble${bubbleposition} stooltip-${position}`} style={bubblestyle}>
            
            <div className={bubbleClass}  style={{
              backgroundColor:option.bubbleBackgroundColor?option.bubbleBackgroundColor:''
            }}
              dangerouslySetInnerHTML={{ __html: message }}
            >

            </div>
          </div>
        }
       

        <span
          className='stooltip-trigger'
        >
          {props.children}
        </span>
      </div>

    )
  


}


export default SsogiTooltip;