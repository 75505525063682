import React from "react";


export const  SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
})=> {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select className="selectFilter"
            value={filterValue||''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value={''}>All</option>

            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}
export const NumberRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'

            }}
        >
            <input className="rangeFilter"
                value={filterValue[0] || ''}
                type="text"
                onChange={e => {
                    const val = e.target.value
                    // console.log(val);
                    // console.log("parseInt(0,10):"+parseInt(0,10));
                    setFilter((old = []) => {
                        let check = [(val) ? parseInt(val, 10) : undefined, old[1]];
                        // console.log("체크");
                        // console.log(check);
                        return check;
                    }
                    )

                }}
                placeholder={`Min (${min})`}

            />
        &nbsp;~&nbsp;
            <input className="rangeFilter"
                value={filterValue[1] || ''}
                type="text"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], (val) ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}

            />
        </div>
    )
}