import { useCallback, useEffect, useMemo, useState } from "react";
import { BigCard, GPtable } from "../../components";
import { SelectColumnFilter } from "../../util/Filter";
import { getSellerPaylogAPI } from "../../api/seller";
import { toast } from "react-toastify";

let PayLogTable_function;
const Paylogs = (props) => {
  const { logout } = props;
  const [data, setData] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        Header: "지급일",
        accessor: "seller_paylog_pay_date",
        useFilter: true,
        width: 200,
      },
      {
        Header: "기관아이디",
        accessor: "agency_ID",
        useFilter: true,
        width: 200,
      },
      {
        Header: "기관이름",
        accessor: "agency_name",
        useFilter: true,
        width: 200,
      },
      {
        Header: "납입구독료(원, VAT 제외)",
        accessor: "seller_paylog_total_income",
        useFilter: true,
        width: 200,
      },
      {
        Header: "수수료(%)",
        accessor: "seller_paylog_ratio",
        useFilter: true,
        width: 200,
      },
      {
        Header: "세금(원)",
        accessor: "seller_paylog_tax",
        useFilter: true,
        width: 200,
      },
      {
        Header: "지급액(원)",
        accessor: "seller_paylog_pay",
        useFilter: true,
        width: 200,
      },
      {
        Header: "지급항목",
        accessor: "seller_paylog_method",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 200,
      },
      {
        Header: "지급은행",
        accessor: "seller_paylog_bank",
        useFilter: true,
        Filter: SelectColumnFilter,
        width: 200,
      },
    ];
  }, []);

  const [refreshLoading, setRefreshLoading] = useState(false);

  const refreshSellerPaylog = useCallback(async () => {
    try {
      setData([]);
      PayLogTable_function?.set_loading(true);
      setRefreshLoading(true);
      const res = await getSellerPaylogAPI();

      if (!res.valid) {
        toast.error(res.msg);
        if (res.redirect) {
          logout();
        }
        return;
      }

      setData(res.results);
    } catch (err) {
      console.log("err", err);
      toast.error(err?.message);
    } finally {
      PayLogTable_function?.set_loading(false);
      setRefreshLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    refreshSellerPaylog();
  }, [refreshSellerPaylog]);

  return (
    <BigCard
      title={() => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <strong>영업 수수료 지급 내역</strong>
          </div>
        );
      }}
    >
      <GPtable
        className="paylogTable"
        columns={columns}
        data={data}
        defaultPageSize={12}
        defaultToolbar={true}
        toolbar={() => {
          return (
            <>
              <div>
                <button className="btn btn-toolbar btn-orange" disabled={refreshLoading} onClick={refreshSellerPaylog}>
                  새로고침
                </button>
              </div>
            </>
          );
        }}
        GPF={(GPtable_f) => {
          PayLogTable_function = GPtable_f;
        }}
      />
    </BigCard>
  );
};

export default Paylogs;
